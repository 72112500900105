<template>
	<el-dialog
		v-model="visible"
		width="750px"
		class="container"
		title="新增维保项目"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading" v-if="visible">
			<!-- <el-input placeholder="请输入关键字进行过滤" v-model="keyword" /> -->
			<el-tree
				ref="treeRef"
				:data="data"
				show-checkbox
				default-expand-all
				node-key="id"
				highlight-current
				:props="defaultProps"
				:default-checked-keys="defaultKeys"
			/>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { defineExpose, inject, ref, watch } from 'vue'
import { useState, useModal, useFetch } from '@/utils/hooks.js'
import { maintainTree } from '@/api/vehicle'
import EventBus from '@/event/EventBus'

const $message = inject('$message')
const treeRef = ref(null)
const { visible, setVisible } = useModal()
const [data, setData] = useState([])
const [origin, setOrigin] = useState([])
const [keyword, _] = useState('')
const [defaultKeys, setKeys] = useState([])
const { isLoading, fetchData } = useFetch()

const defaultProps = { label: 'name' }

// watch(
// 	() => keyword.value,
// 	val => {
// 		console.log('keyword', val)
// 		let list = []
// 		for (const item of origin.value) {
// 			const res = findPath(item)
// 			console.log('res', res)
// 			if (res && res.filter(Boolean).length) {
// 				list = list.concat(res.flat())
// 			}
// 		}
// 		const value = list.filter(Boolean)
// 		console.log('res', value, value[0])
// 		setData(value)
// 	},
// )
function findPath(item, path = []) {
	if (item.name.includes(keyword.value)) {
		return path
	}
	if (item.children) {
		return item.children.map(val => findPath(val, [...path, item]))
	}
}
async function open(data) {
	setVisible(true)
	fetchList(data)
}

function onClose() {
	setVisible(false)
	setData([])
}
async function onSubmit() {
	const selected = treeRef.value.getCheckedNodes()
	console.log('selected', selected)
	const res = selected.map(item => {
		const parent = data.value.find(
			node => node.children && node.children.find(child => child.id === item.id),
		)
		if (!parent) return null
		return Object.assign(item, { parent, parentName: parent.name })
	})
	EventBus.$emit('select-maintain', res.filter(Boolean))
	onClose()
}

async function fetchList(params) {
	const { err, data } = await fetchData(maintainTree)
	if (err) return $message.error(err.message)
	setData(data)
	setOrigin(data)
	console.log('所有维保项目', data)
	if (Array.isArray(params) && params.length) {
		const checked = params.map(item => (item.projectIdLv2 ? item.projectIdLv2 : item.id))
		console.log('checked', checked)
		setKeys(checked)
	}
}

defineExpose({ open })
</script>

<style lang="less" scoped></style>

<template>
	<el-dialog
		v-model="visible"
		width="800px"
		class="container"
		:title="title"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading" v-if="visible">
			<yi-form
				:model="carInfo"
				:rules="rules"
				ref="formRef"
				label-width="90px"
				size="small"
				:form-list="formList"
				:gutter="80"
			>
				<template #licensePlateNum>
					<el-select-v2
						placeholder="请选择车牌号"
						:options="licensePlateOptions"
						filterable
						size="default"
						v-model="carInfo.licensePlateNum"
						@change="onNumChange"
						:disabled="disabled"
						style="width: 190px"
					>
						<template #default="{ item }">
							<div class="custom-option">
								<span>{{ item.label }}</span>
								<span>{{ mapDict('status', item.status) }}</span>
							</div>
						</template>
					</el-select-v2>
				</template>
				<template #maintenanceTime>
					<el-date-picker
						size="medium"
						style="width: 190px"
						:disabled="disabled"
						v-model="model.maintenanceTime"
						:disabled-date="disabledDate"
						placeholder="请选择维保时间"
					/>
				</template>
			</yi-form>
			<div class="maintenance-title">
				<div>
					<span>维保项目</span>
					<span v-if="!disabled" class="cursor" style="margin-left: 12px" @click="addProject">
						新增维保项目
					</span>
				</div>
				<div>
					<span class="label">维保费用(元)</span>
					<el-input
						disabled
						style="width: 190px; margin: 0 12px; margin-right: 12px"
						v-model="total"
					/>
				</div>
			</div>

			<el-form :model="formData" :rules="rules" ref="tableRef">
				<el-table
					border
					style="margin: 20px 0; width: 640px; margin-left: 60px"
					:data="formData.tableData"
					:columns="columns"
				>
					<!-- <el-table-column prop="projectType" label="项目类型" width="100">
						<template #default="scope">
							<p>{{ mapDict('maintaining_project_type', scope.row.projectType) }}</p>
						</template>
					</el-table-column> -->
					<el-table-column prop="projectIdLv1" label="维保一级项目">
						<template #default="scope">
							<el-form-item
								:prop="`tableData.${scope.$index}.projectIdLv1`"
								:rules="rules.projectIdLv1"
							>
								<el-select
									filterable
									:disabled="disabled"
									placeholder="请选择项目"
									style="margin-top: 20px"
									v-model="scope.row.projectIdLv1"
									@change="onL1Change(scope.$index, scope.row.projectIdLv1)"
								>
									<el-option
										v-for="item in scope.row.options1"
										:key="item.id"
										:label="item.name"
										:value="item.id"
									></el-option>
								</el-select>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column prop="projectIdLv2" label="维保二级项目">
						<template #default="scope">
							<el-form-item
								:prop="`tableData.${scope.$index}.projectIdLv2`"
								:rules="rules.projectIdLv2"
							>
								<el-select
									:disabled="disabled"
									filterable
									style="margin-top: 20px"
									placeholder="请选择项目"
									v-model="scope.row.projectIdLv2"
									@change="onL2Change(scope.$index, scope.row.projectIdLv2)"
								>
									<el-option
										v-for="item in scope.row.options2"
										:key="item.id"
										:label="item.name"
										:value="item.id"
									></el-option>
								</el-select>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column label="维保金额" width="140">
						<template #default="scope">
							<el-form-item :prop="`tableData.${scope.$index}.amount`" :rules="rules.amount">
								<el-input
									:disabled="disabled"
									maxLength="7"
									v-model="scope.row.amount"
									style="width: 100%; margin-top: 20px"
								></el-input>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column prop="date" label="操作" width="70" v-if="!disabled">
						<template #default="scope">
							<!-- <span class="cursor" style="margin-right: 10px" @click="onEdit">编辑</span> -->
							<span class="cursor" style="color: #f56c6c" @click="onDelete(scope.row)">删除</span>
						</template>
					</el-table-column>
				</el-table>
			</el-form>

			<yi-form
				:model="model"
				:rules="rules"
				ref="repairRef"
				label-width="120px"
				size="small"
				:form-list="repairForm"
				:gutter="80"
			/>
			<div style="margin-left: 60px">
				<p style="font-weight: 600; font-size: 12px">维保说明</p>
				<el-input
					maxLength="200"
					style="width: 640px; margin-bottom: 20px"
					type="textarea"
					v-model="model.remark"
					:disabled="disabled"
					:autosize="{ minRows: 4 }"
				/>
			</div>
			<div style="margin-left: 60px">
				<p class="title">
					维保附件
					<span>({{ model.attachments ? model.attachments.length : 0 }}/90)</span>
				</p>
				<el-form :model="model" :rules="rules" ref="uploadRef">
					<el-form-item prop="attachments">
						<div style="display: flex; flex-direction: row">
							<span style="color: #f56c6c; margin-right: 4px; line-height: 24px">*</span>
							<yi-file-uploader
								v-model="model.attachments"
								productType="renrenjia"
								sourceType="image"
								:max="90"
								:uploaderSize="20"
								:disabled="disabled"
								accept=".jpg,.jpeg,.png"
							/>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<template #footer>
			<div class="dialog-footer" v-if="!disabled">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { defineExpose, ref, inject, defineEmits, computed } from 'vue'
import dayjs from 'dayjs'
import { useState, useModal, useFetch, useDict, useSelect } from '@/utils/hooks.js'
import { form, repair, uploadForm as upload } from './config'
import { status } from '../enums'
import {
	storageSelect,
	carSelectList,
	vehicleDetail,
	assetMaintenance,
	maintenanceDetail,
	maintainTree,
} from '@/api/vehicle'

const $message = inject('$message')
const emit = defineEmits(['on-refresh', 'add-project'])
const formList = ref(form)
const repairForm = ref(repair)
const uploadForm = ref(upload)
const { visible, title, setVisible, setTitle } = useModal()
const [model, setModel] = useState({
	maintenanceTime: new Date(),
})
const [dict, setDict] = useState({})
const [disabled, setDisabled] = useState(false)
const [carInfo, setCarInfo] = useState({})
const [repairList, setRepair] = useState([])
const [licensePlateOptions, setOptions] = useState([])
const [l1, setL1] = useState([])
const [l2, setL2] = useState([])
const [tableData, setTable] = useState([{}])
const { isLoading, fetchData } = useFetch()
const [formData, setForm] = useState({ tableData: tableData.value })

const columns = []
const total = computed(() => {
	const res = tableData.value.reduce((acc, cur) => {
		acc += cur.amount ? cur.amount * 1 : 0
		return acc
	}, 0)
	return res.toFixed(2)
})
const rules = {
	amount: [
		{ required: true, message: '请输入维保金额' },
		{ pattern: /^\d+(?:\.\d{1,2})?$/, message: '维保金额格式有误', trigger: 'blur' },
	],
	mileage: [{ pattern: /^[0-9]*$/, message: '车辆里程只能是整数' }],
	attachments: [{ message: '请上传维保附件', required: true }],
	projectIdLv1: [{ required: true, message: '请选择维保一级项目' }],
	projectIdLv2: [{ required: true, message: '请选择维保二级项目' }],
}
const formRef = ref(null)
const repairRef = ref(null)
const uploadRef = ref(null)
const tableRef = ref(null)

function disabledDate(time) {
	return time.getTime() <= dayjs().subtract(15, 'day') || time.getTime() >= dayjs()
}

async function onNumChange(val) {
	const { err, data } = await fetchData(vehicleDetail, val)
	if (err) $message.error(err.message)
	const { id, belongCompanyId } = data
	setCarInfo({ ...data, maintenanceType: carInfo.value.maintenanceType })
	setModel(Object.assign(model.value, { carInfoId: id }))
	getRepair(belongCompanyId)
}

function addProject() {
	const data = { options1: l1.value, options2: l2.value }
	setTable(tableData.value.concat(data))
	setForm({ tableData: tableData.value })
}
function onDelete(row) {
	const index = tableData.value.findIndex(item => item.id === row.id)
	tableData.value.splice(index, 1)
}

function onL1Change(index, id) {
	const target = l1.value.find(item => item.id === id)
	tableData.value[index].projectType = target.projectType
	if (tableData.value[index].projectIdLv2) tableData.value[index].projectIdLv2 = ''
	if (target.children) {
		tableData.value[index].options2 = target.children
	} else {
		tableData.value[index].options2 = []
	}
	setForm({ tableData: tableData.value })
}
function onL2Change(index, id) {
	const parent = l1.value.find(item => {
		if (!item.children) return false
		return item.children.find(child => child.id === id)
	})
	// tableData.value[index].options1 = [parent]
	tableData.value[index].projectType = parent.projectType
	tableData.value[index].projectIdLv1 = parent.id
	tableData.value[index].projectNameLv1 = parent.name
	tableData.value[index].options2 = parent.children || []
	const target = l2.value.find(item => item.id === id)
	tableData.value[index].projectNameLv2 = target.name
	setForm({ tableData: tableData.value })
}

async function open(id, type, maintenanceType) {
	fetchMaintain()
	fetchList()
	fetchDict()
	if (maintenanceType) {
		carInfo.value.maintenanceType = maintenanceType
	}
	const licensePlateNum = formList.value.find(item => item.prop === 'licensePlateNum')
	licensePlateNum.attrs.disabled = !!type
	uploadForm.value.forEach(item => (item.attrs.disabled = !!type))
	repairForm.value.forEach(item => (item.attrs.disabled = !!type))
	if (id) {
		fetchDetail(id)

		if (type) {
			setDisabled(true)
			setTitle('维保单详情')
			setVisible(true)
			return
		}
		setTitle('编辑维保单')
	} else {
		setTitle('新增维保单')
	}

	licensePlateNum.attrs.disabled = !!id
	setVisible(true)
}
async function fetchDetail(id) {
	const { err, data } = await fetchData(maintenanceDetail, id)
	if (err) return $message.error(err.message)
	getRepair(data.companyId)
	setModel(data)
	setCarInfo(data)
	const getOptions = id => {
		const target = l1.value.find(item => item.id === id)
		const options2 = target.children
		return { options1: l1.value, options2 }
	}
	const tableData = data.maintenanceDetails.map(item => ({
		...item,
		parentName: item.projectNameLv1,
		name: item.projectNameLv2,
		...getOptions(item.projectIdLv1),
	}))

	setTable(tableData)
	setForm({ tableData })
}
async function fetchDict() {
	const dict = await useDict(['maintaining_project_type', 'maintenance_type'])
	const carStatus = { status }
	const item = formList.value.find(item => item.prop === 'maintenanceType')
	item.options = dict.maintenance_type.map(item => ({ label: item.name, value: item.code * 1 }))
	if (dict) setDict(Object.assign(dict, carStatus))
}

function mapDict(key, value) {
	if ([undefined, null, ''].includes(value)) return '请选择项目'
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)
	return target ? target.name : '--'
}

function onClose() {
	setVisible(false)
	setModel({ maintenanceTime: new Date() })
	setCarInfo({})
	setTable([])
	setDisabled(false)
	setForm({ tableData: [] })
}
async function onSubmit() {
	await Promise.all([
		formRef.value.validate(),
		repairRef.value.validate(),
		uploadRef.value.validate(),
		tableRef.value ? tableRef.value.validate() : () => {},
	])
	const maintenanceDetails = tableData.value.map(item => {
		const {
			amount,
			parent = {},
			name,
			id,
			projectIdLv1,
			projectIdLv2,
			projectNameLv1,
			projectNameLv2,
			projectType,
		} = item
		return {
			amount,
			projectIdLv1: parent.id || projectIdLv1,
			projectIdLv2: projectIdLv2 || id,
			projectNameLv1: parent.name || projectNameLv1,
			projectNameLv2: name || projectNameLv2,
			projectType: parent.projectType || projectType,
		}
	})
	const { maintenanceType } = carInfo.value
	const { maintenanceTime } = model.value
	const params = {
		...model.value,
		maintenanceType,
		maintenanceDetails,
		maintenanceCost: total.value,
		maintenanceTime: dayjs(maintenanceTime).format('YYYY-MM-DD HH:mm:ss'),
	}
	console.log('repairList', repairList.value, params)
	const repairCompany = repairList.value.find(item => item.value === params.repairCompanyId)
	const repairCompanyName = repairCompany ? repairCompany.label : ''
	params.repairCompanyName = repairCompanyName

	const { err } = await fetchData(assetMaintenance, params)
	if (err) return $message.error(err.message)
	$message.success('操作成功')
	emit('on-refresh')
	onClose()
}

async function fetchList() {
	const { err, data } = await fetchData(carSelectList, { statusList: [1, 2, 3, 4, 5, 6, 7, 8] })
	if (err) return $message.error(err.message)
	const licensePlateNum = formList.value[0]

	const options = data.map(item => ({
		label: item.licensePlateNum,
		value: item.id,
		disabled: ![1, 4, 2, 7, 8].includes(item.status),
		...item,
	}))
	const valid = []
	const invalid = []
	for (const option of options) {
		if ([1, 4, 2, 7, 8].includes(option.status)) valid.push(option)
		else invalid.push(option)
	}
	setOptions(valid.concat(invalid))
	licensePlateNum.on = { change: onNumChange }
	setModel({ ...model.value })
}
async function getRepair(companyId) {
	const target = repairForm.value.find(item => item.prop === 'repairCompanyId')
	target.options = []
	model.value.repairCompanyId = ''
	const { err, data } = await useSelect(() => storageSelect(10, { companyId, status: 1 }))
	if (err) return console.log('err', err)
	setRepair(data)
	target.options = data
	if (data.length === 1) {
		model.value.repairCompanyId = data[0].value
	}
}
// onMounted(() => {
// 	EventBus.$on('select-maintain', res => {
// 		console.log('选中的维保项目', res, tableData.value)
// 		const data = res.map(item => {
// 			const exist = tableData.value.find(val => {
// 				const id = val.projectIdLv2 ? val.projectIdLv2 : val.id
// 				return id === item.id
// 			})
// 			if (exist) return exist
// 			return item
// 		})
// 		console.log(
// 			'整理后的数据',
// 			data.map(item => (item.projectIdLv2 ? item.projectIdLv2 : item.id)),
// 		)
// 		setTable(data)
// 		setForm({ tableData: data })
// 	})
// })
async function fetchMaintain() {
	const { err, data } = await fetchData(maintainTree)
	if (err) return $message.error(err.message)

	setL1(data)
	const l2 = data.reduce((acc, cur) => {
		if (cur.children) {
			acc = acc.concat(cur.children)
		}
		return acc
	}, [])
	setL2(l2)
	const table = tableData.value.map(item => ({ ...item, options1: data, options2: l2 }))
	setTable(table)
	setForm({ tableData: table })
}

defineExpose({ open })
</script>
<style lang="less">
.el-select-v2__placeholder {
	font-weight: 400 !important;
}
.el-select-dropdown__option-item {
	font-size: 14px !important;
}
</style>
<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.maintenance-title {
	.flexible(row, center, space-between);
	padding-left: 60px;
	padding-right: 48px;
}
.cursor {
	cursor: pointer;
	color: #02a7f0;
}
.label {
	color: #606266;
	font-size: 12px;
	font-weight: 600;
}
.custom-option {
	.flexible(row, center, space-between);
}
</style>

import { maintenanceStatus } from '../enums'
export const columns = [
	{
		prop: 'carModelName',
		label: '车型名称',
		width: '280px',
	},
	{
		prop: 'licensePlateNum',
		label: '车牌号',
		width: '120px',
	},
	{
		prop: 'carColor',
		label: '颜色',
	},
	{
		prop: 'driverName',
		label: '司机姓名',
		width: '120px',
	},
	{
		prop: 'maintainerName',
		label: '维保专员',
		width: '120px',
	},
	{
		prop: 'maintenanceType',
		label: '维保类型',
		width: '120px',
	},
	{
		prop: 'status',
		label: '维保状态',
		width: '120px',
	},
	{
		prop: 'maintenanceCost',
		label: '维保费用（元）',
		width: '140px',
	},
	{
		prop: 'repairCompanyName',
		label: '维修厂名称',
		width: '240px',
	},
	{
		prop: 'maintenanceEndTime',
		label: '维保结束时间',
		width: '220px',
	},
	{
		prop: 'mileage',
		label: '公里数（KM）',
		width: '140px',
	},
	{
		prop: 'maintainerName',
		label: '维保专员',
		width: '120px',
	},
	{
		prop: 'maintenanceType',
		label: '维保类型',
		width: '120px',
	},

	{
		prop: 'carNum',
		label: '自编号',
		width: '120px',
	},
	{
		prop: 'carColor',
		label: '颜色',
	},
	{
		prop: 'driverName',
		label: '司机姓名',
		width: '120px',
	},
	{
		prop: 'carInfoCompanyName',
		label: '所属公司',
		width: '280px',
	},
	{
		prop: 'createUserName',
		label: '创建人',
		width: '140px',
	},
	{
		prop: 'createTime',
		label: '创建时间',
		width: '220px',
	},
	{
		prop: 'operation',
		label: '操作',
		width: '80px',
		fixed: 'right',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			attr: 'keyword',
			label: '车辆信息',
			type: 'search',
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			clearable: true,
			width: 358,
		},
		{
			attr: 'repairCompanyId',
			label: '维修厂',
			type: 'select',
			placeholder: '选择维修厂',
			options: [],
		},
		{
			attr: 'statuses',
			label: '维保状态',
			type: 'multi-select',
			placeholder: '选择维保状态',
			options: maintenanceStatus,
		},
		{
			attr: 'maintenanceCost',
			label: '维保金额',
			type: 'input-range',
			placeholder: '请输入',
		},
		{
			attr: 'firstLevel',
			label: '一级维保项目',
			type: 'select',
			placeholder: '选择一级维保项目',
			options: [],
		},
		{
			attr: 'secondLevel',
			label: '二级维保项目',
			type: 'select',
			placeholder: '选择二级维保项目',
			options: [],
		},
		{
			attr: 'carInfoCompanyIds',
			label: '所属公司',
			type: 'multi-select',
			placeholder: '请选择所属公司',
			width: 320,
			options: [],
		},
		{
			attr: 'maintenanceTime',
			label: '维保时间',
			type: 'date',
		},
		{
			attr: 'maintenanceType',
			label: '维保类型',
			type: 'select',
			placeholder: '选择维保类型',
		},
		{
			attr: 'projectTypes',
			label: '项目类型',
			type: 'multi-select',
			placeholder: '选择项目类型',
			options: [],
		},
		{
			attr: 'maintainerId',
			label: '维保专员',
			type: 'select',
			placeholder: '选择维保专员',
		},
		{
			attr: 'createUser',
			label: '创建人',
			type: 'select',
			placeholder: '选择创建人',
		},
	],
}

export const form = [
	{
		prop: 'licensePlateNum',
		label: '车牌号',
		required: true,
		component: 'el-select-v2',
		custom: true,
		attrs: { placeholder: '请选择车牌号', options: [], filterable: true },
	},
	{
		prop: 'maintenanceType',
		label: '维保类型',
		component: 'el-select',
		options: [],
		attrs: { disabled: true },
	},
	{
		prop: 'carModelName',
		label: '车辆车型',
		component: 'el-input',
		// cWidth: 580,
		attrs: { disabled: true },
	},
	{
		prop: 'maintenanceTime',
		label: '维保时间',
		custom: true,
		attrs: {},
	},
	{
		prop: 'carNum',
		label: '自编号',
		component: 'el-input',
		attrs: { disabled: true },
	},
	{
		prop: 'carColor',
		label: '车辆颜色',
		component: 'el-input',
		attrs: { disabled: true },
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		component: 'el-input',
		attrs: { disabled: true },
	},
	{
		prop: 'maintainerName',
		label: '维保专员',
		component: 'el-input',
		attrs: { disabled: true },
	},
]

export const repair = [
	{
		prop: 'repairCompanyId',
		label: '维修厂',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择维修厂' },
	},
	{
		prop: 'mileage',
		label: '车辆里程(KM)',
		required: true,
		component: 'el-input',
		attrs: { maxLength: 7, placeholder: '请输入车辆里程' },
	},
]

export const uploadForm = [
	{
		prop: 'attachments',
		label: '维保附件',
		required: true,
		component: 'yi-file-uploader',
		cWidth: 500,
		attrs: {
			max: 9,
			uploaderSize: 20,
			message: '请上传维保附件',
			productType: 'renrenjia',
			sourceType: 'image',
		},
	},
]

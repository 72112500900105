<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">维保管理</div>
				<div class="header-button">
					<el-button
						@click="onCreate(1)"
						v-permission="'pc/car-center/car-manage/save/maintenance'"
					>
						新增维修
					</el-button>
					<el-button @click="onCreate(2)" v-permission="'pc/car-center/car-manage/save/overhaul'">
						新增整备
					</el-button>
					<el-button @click="onCreate(3)" v-permission="'pc/car-center/car-manage/save/upkeep'">
						新增保养
					</el-button>
					<el-button
						@click="onCreate(4)"
						v-permission="'pc/car-center/car-manage/save/easy-damage'"
					>
						新增易损件
					</el-button>
					<el-button
						type="primary"
						@click="onOpen"
						v-permission="'pc/car-center/car-manage/repair-complate'"
					>
						维保完成
					</el-button>
					<el-button
						@click="onExport"
						:loading="buttonLoading"
						v-permission="'pc/car-center/car-manage/export'"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter
					ref="filterRef"
					:setting="setting"
					@change="onChangeFilter"
					@singleChange="onSelectChange"
				/>
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					showSelection
					autoWidth
					@selection-change="onSelectionChange"
					:params="{ page }"
				>
					<template #carModelName="{ value, row }">
						<span class="click" @click="onDetail(row)">{{ value }}</span>
					</template>
					<template #status="{ value }">
						<span>{{ mapDict('maintenanceStatus', value) }}</span>
					</template>
					<template #maintenanceType="{ value }">
						<span>{{ mapDict('maintenance_type', value) }}</span>
					</template>
					<template #operation="{ row }">
						<span v-permission="'pc/car-center/car-manage/edit'" class="click" @click="onEdit(row)">
							编辑
						</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<maintenance-form ref="formRef" @on-refresh="refresh" @add-project="showAddProject" />
		<add-project ref="addRef" />
		<el-dialog
			v-model="visible"
			width="600px"
			class="container"
			title="维保完成"
			:before-close="onClose"
			:close-on-click-modal="false"
			append-to-body
			destroy-on-close
		>
			<div>
				<span>
					已选中
					<span style="color: #d9001b">{{ selected.length }}</span>
					辆车，车辆明细如下：
				</span>
				<table border="1" cellspacing="0" cellpadding="5">
					<tr>
						<td style="width: 300px">车牌号</td>
						<td style="width: 200px">出租状态</td>
					</tr>
					<tr v-for="(item, index) in selected" :key="index">
						<td>{{ item.licensePlateNum }}</td>
						<td>
							<el-select
								:disabled="item.carInfoStatus == 7"
								v-model="item.isRent"
								placeholder="选择出租状态"
							>
								<el-option
									v-for="(option, i) in [
										{ label: '可出租', value: true },
										{ label: '不可出租', value: false },
									]"
									:key="i"
									:label="option.label"
									:value="option.value"
								></el-option>
							</el-select>
						</td>
					</tr>
				</table>
				<p>确定批量更新以上车辆维修单为完成状态</p>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button type="primary" @click="onComplete" :loading="loading">确定</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script name="MaintenanceManagement">
import { ref, defineComponent, inject, getCurrentInstance, onActivated, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { setting as s, columns } from './config'
import { InitTable, useFetch, useState, useDict } from '@/utils/hooks.js'
import { getStore } from '@/utils/store'
import { handleExport } from '@/utils/util'
import {
	exportMaintenance,
	maintenanceList,
	storageSelect,
	maintenanceSelect,
	maintainCompleted,
	maintainTree,
	companySelect,
	getUserList,
} from '@/api/vehicle'
import enums from '../enums'
import MaintenanceForm from './form'
import AddProject from './addProject'

export default defineComponent({
	name: 'MaintenanceManagement',
	components: {
		MaintenanceForm,
		AddProject,
	},
	setup() {
		const $message = inject('$message')
		const formRef = ref(null)
		const addRef = ref(null)
		const filterRef = ref(null)
		const status = ref(null)
		const params = { page: { current: 1, size: 20 } }
		const service = maintenanceList
		const table = new InitTable(service, params)
		const [selected, setSelected] = useState([])
		const [visible, setVisible] = useState(false)

		const [buttonLoading, setLoading] = useState(false)
		const [project, setProject] = useState([])
		const [dicts, setDicts] = useState({})
		const setting = ref(s)
		const { fetchData } = useFetch()
		const userInfo = getStore({ name: 'userInfo' })
		const methods = {
			refresh() {
				table.fetchList()
			},
			onSelectChange(params) {
				const { attr, value } = params
				if (attr === 'firstLevel') {
					table.params.secondLevel = undefined
					filterRef.value.setFilterData('secondLevel', undefined)
					const secondLevel = setting.value.filters.find(item => item.attr === 'secondLevel')
					const target = project.value.find(item => item.id === value)
					secondLevel.options =
						target && target.children
							? target.children.map(item => ({ code: item.id, name: item.name }))
							: []
				}
			},
			onChangeFilter(params) {
				console.log('params', params)
				const { maintenanceTime, firstLevel, secondLevel, maintenanceCost, ...rest } = params
				let projectIds = []
				if (!firstLevel) {
					const secondLevel = setting.value.filters.find(item => item.attr === 'secondLevel')
					secondLevel.options = []
				}
				if (firstLevel) projectIds.push(firstLevel)
				if (secondLevel) projectIds = [secondLevel]

				const data = { ...rest }
				if (projectIds.length) data.projectIds = projectIds
				if (Array.isArray(maintenanceTime) && maintenanceTime.length) {
					const [start, end] = maintenanceTime
					data.maintenanceStTime = start
					data.maintenanceEndTime = end
				}
				if (Array.isArray(maintenanceCost) && maintenanceCost.length) {
					const [start, end] = maintenanceCost
					data.maintenanceCostMin = start
					data.maintenanceCostMax = end
				}
				table.onChangeFilter(data)
			},
			onCreate(type) {
				formRef.value.open(null, null, type)
			},
			async onExport() {
				setLoading(true)
				try {
					const res = await exportMaintenance(table.params)
					if (res) handleExport(res, '维保管理')
				} catch (err) {
					console.log('err', err)
					$message.error(err.message)
				} finally {
					setLoading(false)
				}
			},
			onClose() {
				setVisible(false)
			},
			onOpen() {
				if (!selected.value.length) return $message.error('请选择车辆')
				const invalid = selected.value.filter(item => item.status != 3)
				if (invalid.length) {
					const msg =
						invalid.map(item => item.licensePlateNum).join('、') +
						'等' +
						invalid.length +
						'辆车不在维保中，不能操作维保完成，请重新选择车辆'
					return $message.error(msg)
				}
				selected.value = selected.value.map(item => {
					return { isRent: item.carInfoStatus == 7 ? true : null, ...item }
				})
				setVisible(true)
			},
			onComplete() {
				if (selected.value.some(item => [undefined, null].includes(item.isRent)))
					return $message.error('请选择出租状态')
				const params = selected.value.map(item => ({ id: item.id, isRent: item.isRent }))
				maintainCompleted(params)
					.then(res => {
						if (res.code === 200) {
							$message.success('操作成功')
							table.fetchList()
							setVisible(false)
						} else throw new Error(res.msg || res.message)
					})
					.catch(e => $message.error(e.message))
			},
			mapDict(key, value) {
				const e = dicts.value[key]

				if (!e) return '--'
				const target = e.find(item => item.code == value)
				return target ? target.name : '--'
			},
			showAddProject(data) {
				addRef.value.open(data)
			},
			onSelectionChange(list) {
				setSelected(list)
			},
			onEdit(row) {
				const { status } = row
				if (status != 3) return $message.error('当前状态不可编辑')
				formRef.value.open(row.id)
			},
			onDetail(row) {
				formRef.value.open(row.id, 'detail')
			},
		}
		async function fetchSelect() {
			const [userRes, companyRes] = await Promise.all([
				fetchData(getUserList, { page: { current: 1, size: 999 } }),
				fetchData(companySelect, { isPermission: true }),
			])
			console.log('fetch select', userRes, companyRes)
			if (userRes.err) {
				$message.error(userRes.err.message)
			} else {
				const user = setting.value.filters.find(item => item.attr === 'createUser')
				user.options = userRes.data.records.map(item => ({ name: item.realName, code: item.id }))
			}
			if (companyRes.err) {
				$message.error(companyRes.err.message)
			} else {
				const company = setting.value.filters.find(item => item.attr === 'carInfoCompanyIds')
				company.options = companyRes.data.map(item => ({
					name: item.companyName,
					code: item.companyId,
				}))
			}
		}
		async function fetchMaintenance() {
			const { companyId } = userInfo
			const list = companyId ? companyId.split(',') : []
			const { err, data } = await fetchData(maintenanceSelect, 'Mainten', list, { isAll: true })
			if (err) $message.error(err.message)
			const company = setting.value.filters.find(item => item.attr === 'maintainerId')
			company.options = data.map(item => ({ name: item.realName, code: item.id }))
		}
		async function fetchCompany() {
			const { err, data } = await fetchData(companySelect, { isPermission: true })
			if (err) $message.error(err.message)
			const company = setting.value.filters.find(item => item.attr === 'carInfoCompanyIds')
			company.options = data.map(item => ({ name: item.companyName, code: item.companyId }))
		}
		async function getList() {
			const { err, data } = await fetchData(storageSelect, 10, { isPermission: true })
			if (err) $message.error(err.message)
			const maintenance = setting.value.filters.find(item => item.attr === 'repairCompanyId')
			maintenance.options = data.map(item => ({ code: item.id, name: item.name }))
		}

		async function getDict() {
			const dict = await useDict(['maintenance_type', 'maintaining_project_type'])
			const projectTypes = setting.value.filters.find(item => item.attr === 'projectTypes')
			projectTypes.options = dict.maintaining_project_type
			const maintenanceType = setting.value.filters.find(item => item.attr === 'maintenanceType')
			maintenanceType.options = dict.maintenance_type
			setDicts({ ...dict, ...enums })
		}
		async function getProject() {
			const { err, data } = await fetchData(maintainTree)
			if (err) return $message.error(err.message)
			setProject(data)
			const firstLevel = setting.value.filters.find(item => item.attr === 'firstLevel')
			firstLevel.options = data.map(item => ({ code: item.id, name: item.name }))
		}

		function init() {
			getList()
			fetchSelect()
			fetchMaintenance()
			getDict()
			getProject()
			fetchCompany()
			const route = useRoute()
			const query = route.query
			if (Object.keys(query).length > 0) {
				params.keyword = query.keyword || ''
				params.carInfoCompanyIds = query?.companyIds?.split(',') || []
				if (query.statuses) {
					params.statuses = [query.statuses * 1]
				}
				const [start, end] = query?.date?.split(',') || []
				params.maintenanceStTime = (start && `${start} 00:00:00`) || null
				params.maintenanceEndTime = (end && `${end} 23:59:59`) || null
				params.maintenanceTime =
					(start && [`${start} 00:00:00`, end ? `${end} 23:59:59` : `${start} 00:00:00`]) || null
				params.projectTypes = (query.status && query.status.split(',')) || null
				const ins = getCurrentInstance()
				ins.refs.filterRef && ins.refs.filterRef.manualSetParams(params)
			} else {
				table.fetchList()
			}
		}
		onActivated(() => {
			init()
		})
		onMounted(() => {
			init()
		})
		return {
			setting,
			columns,
			formRef,
			filterRef,
			addRef,
			status,
			visible,
			selected,
			buttonLoading,
			...table.res,
			...methods,
		}
	},
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
	.click {
		cursor: pointer;
		color: #409eff;
	}
}
table {
	border-collapse: collapse;
}
th,
td {
	border: 1px solid rgba(0, 0, 0, 0.84);
	text-align: center;
	min-width: 120px;
	height: 36px;
}
tr {
	text-align: center;
}
</style>
